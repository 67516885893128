<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveVacation">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>

                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="type_vacation" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="type_vacation"
                                            v-model="type_vacation"
                                            :value="type_vacation"
                                            :item-text="'name'"
                                            :item-value="'id'"
                                            :error="!valid"
                                            :error-messages="errors"
                                            :label="$t('type_vacation')"
                                            :disabled="loading"
                                            :items="type_vacationItems"
                                            @input="type_vacation = $event"
                                        />
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" >
                                    <ValidationProvider ref="admin" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <UserSelection
                                            v-model="admin"
                                            id="auditor"
                                            :label="$t('user_name')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                        />
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" >
                                    <ValidationProvider ref="replacing" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <UserSelection
                                            v-model="replacing"
                                            id="replacing"
                                            :label="$t('replacing')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                        />
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <v-menu v-model="menuFromDate" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <ValidationProvider ref="fromDate" name="date_operation_from" rules="required|date_format"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('start_date')}}</div>
                                                <v-text-field v-model="fromDate" :error-messages="errors"
                                                              :label="$t('start_date')"
                                                              readonly v-on="on"
                                                              @click:clear="fromDate = null"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              hide-details
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable></v-text-field>
                                            </ValidationProvider>
                                        </template>
                                        <v-date-picker ref="picker" v-model="fromDate"
                                                       :max="toDate ? $moment(toDate).format('YYYY-MM-DD') : ''"
                                                       :locale="lang" @change="menuFromDate = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <v-menu v-model="menuToDate" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <ValidationProvider ref="toDate" name="date_operation_to" rules="required|date_format"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('expiration_date')}}</div>
                                                <v-text-field v-model="toDate" :error-messages="errors"
                                                              :label="$t('expiration_date')"
                                                              readonly v-on="on"
                                                              @click:clear="toDate = null"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              hide-details
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable></v-text-field>
                                            </ValidationProvider>
                                        </template>
                                        <v-date-picker ref="picker" v-model="toDate"
                                                       :min="fromDate ? $moment(fromDate).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                       :locale="lang"
                                                       @change="menuToDate = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="comment" rules="min:3|max:65535"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('comment')}}</div>
                                        <v-textarea v-model="comment" type="text" :error-messages="errors"
                                                    :disabled="loading" :label="$t('comment')" rows="5"
                                                    color="primary"
                                                    auto-grow
                                                    clearable outlined
                                                    hide-details
                                                    full-width
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                            >
                                        </v-textarea>
                                     </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">

                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                :to="{name: 'vacation'}"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import SelectInput from "@/components/Form/SelectInput.vue";
    import UserSelection from "@/components/Form/UserSelection.vue";

    export default {
        name: 'VacationForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            SelectInput,
            UserSelection
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                sortBy: "sort",
                sortDir: false,
                options: {},
                type_vacation: null,
                type_vacationItems: [],
                admin: null,
                fromDate:null,
                menuFromDate: false,
                toDate:null,
                menuToDate: false,
                comment: null,
                replacing: null,
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.getTypeVacations()
            this.checkCreate()
        },
        watch: {
            options: {
                handler() {
                    this.getTopics()
                },
                deep: false
            },
        },
        methods: {
            checkCreate() {
                if (this.$route.name === "vacation.create") {
                    this.heading = this.$t('vacation_creation')
                } else {
                    this.heading = this.$t('vacation_editing')
                    if (this.$route.params.id) {
                        this.getVacation()
                    }
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            closeDialogAdd(){
                this.$router.push({
                    name: 'vacation',
                })
            },
            async getVacation() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/vacation/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.type_vacation = res.body.data.type_vacation.id
                        this.admin = res.body.data.admin
                        if(res.body.data && res.body.data.replacing && res.body.data.replacing.id){
                            this.replacing = res.body.data.replacing
                        }
                        this.comment = res.body.data.comment
                        this.fromDate = res.body.data.from_date
                        this.toDate = res.body.data.to_date
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_vacation'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveVacation() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.type_vacation) {
                    formData.append('type_vacation', this.type_vacation)
                }
                if (this.comment) {
                    formData.append('comment', this.comment)
                }
                if (this.admin) {
                    if (this.admin.id) {
                        formData.append('admin', this.admin.id)
                    } else {
                        formData.append('admin', this.admin)
                    }
                }
                if (this.replacing) {
                    if (this.replacing.id) {
                        formData.append('replacing', this.replacing.id)
                    } else {
                        formData.append('replacing', this.replacing)
                    }
                }

                if (
                    this.fromDate !== undefined &&
                    this.fromDate != null &&
                    this.fromDate !== ''
                ) {
                    formData.append('from_date', this.fromDate)
                }
                if (
                    this.toDate !== undefined &&
                    this.toDate != null &&
                    this.toDate !== ''
                ) {
                    formData.append('to_date', this.toDate)
                }

                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/vacation/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('vacation_has_been_updated'))
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('vacation_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/vacation', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('vacation_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.$router.push({
                                    name: 'vacation.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'vacation'
                                })
                            }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('vacation_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getTypeVacations() {
                this.loading = true

                let params = {}


                await this.$http
                    .get("admin/type_vacation", {
                        params: params,
                    })
                    .then(res => {
                        this.type_vacationItems = res.body.data
                    })
                    .catch(err => {
                        this.type_vacationItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },


        }
    }
</script>
